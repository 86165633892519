import {autoinject} from "aurelia-framework";
import {DialogController, DialogService} from "aurelia-dialog";
import {ReportService} from "resources/services/ReportService";
import {UserService} from "resources/services/UserService";
import {HttpClient} from "aurelia-http-client";
import {translations} from "resources/classes/translations";

@autoinject
export class InfoDialog {
    controller: DialogController;
    message: string;
    dialogService: DialogService;
    messages: any[];

    attached() {
        document.body.classList.add('no-toolbar-window');
    }

    deactivate() {
        document.body.classList.remove('no-toolbar-window');
    }

    get api(): string {
        return ReportService.BaseUrl + '/Info';
    }

    constructor(controller: DialogController) {
        this.controller = controller;
        this.message = sessionStorage.getItem('messageData');
        this.messages = JSON.parse(this.message);
        for (const m of this.messages) {
            m.created = new Date(m.created).toLocaleDateString();
            m.showDetails = true;
            m.read = false;
        }
    }

    async markRead(m) {
        let data = {
            userId: UserService.UserName,
            infoId: m.id
        }

        await new HttpClient().post(this.api + '/MarkRead', data)
        m.read = true;
        this.messages.splice(this.messages.indexOf(m), 1);
        sessionStorage.setItem('messageData', JSON.stringify(this.messages));
        sessionStorage.setItem('messages', translations.translate('unread_news').replace("%COUNT%", String(this.messages.length)));
    }
}
