import {DialogController} from "aurelia-dialog";
import {autoinject, bindable} from "aurelia-framework";
import {NitTools} from "../resources/classes/NursitTools";
import {ConfigService} from "../resources/services/ConfigService";

@autoinject()
export class ListSortDialog {
    @bindable sortOrders : ListSortDialogOrder[] = [];

    constructor(protected controller: DialogController) {
        this.sortOrders = NitTools.Clone(ConfigService.sortOrders);
    }

    refreshOrders() {
        this.sortOrders = NitTools.Clone(this.sortOrders);
    }

    incPosition(index) {
        if (index > 0) { // Check if item is not already at the top
            const temp = this.sortOrders[index]; // Store the item to be moved
            this.sortOrders[index] = this.sortOrders[index - 1]; // Replace current item with the one above it
            this.sortOrders[index - 1] = temp; // Put the stored item in the position above

            this.refreshOrders();
        }
    }

    decPosition(index) {
        if (index < this.sortOrders.length - 1) { // Check if item is not already at the bottom
            const temp = this.sortOrders[index]; // Store the item to be moved
            this.sortOrders[index] = this.sortOrders[index + 1]; // Replace current item with the one below it
            this.sortOrders[index + 1] = temp; // Put the stored item in the position below

            this.refreshOrders();
        }
    }
}

export class ListSortDialogOrder {
    public title : string;
    public fieldName : string;
    public index : number = -1;
}
