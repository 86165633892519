import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
import { FhirService } from "resources/services/FhirService";
import {ConfigService} from "../resources/services/ConfigService";

export class Launch {
    cfg;
    html;
    async attached() {
        const http = new HttpClient();
        const result : HttpResponseMessage = await http.get(`${FhirService.Endpoint}/.well-known/smart-configuration`);
        if (result.statusCode === 200 && result.responseType === "json") {
            this.cfg = JSON.parse(result.response);
        }

        if (ConfigService.Debug)
            console.debug(this.cfg);

        window.location.href = this.cfg.authorization_endpoint + `?response_type=code&client_id=scheduler&scope=cdr_all_user_authorities%20online_access%20openid%20profile&redirect_uri=${encodeURIComponent('http://localhost:3000/#/encounter')}&aud=http%3A%2F%2F161.97.139.36%3A8100&state=uAHGwaAHAW4RFERr`;

        //const oa = new HttpClient().get(`${this.cfg.authorization_endpoint}/response_type=code&scope=openid+profile+launch/patient&client_id=my-client-id&state=af0ifjsldkj&redirect_uri=https://myapp.example.com/cb`);
    }
}
